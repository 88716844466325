@import 'sass/abstract/variables';
$fontFamily: 'Inter';

/* ========================================================================================== */

/* X-Large */
@mixin typographyXLarge {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 400;
  font-size: 1.75rem; /* 28px */
  line-height: 2.125rem; /* 34px */

  @media (max-width: $maxMobileWidth) {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
  }
}

/* Large */
@mixin typographyLarge {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 400;
  font-size: 1.375rem; /* 22px */
  line-height: 2rem; /* 32px */

  @media (max-width: $maxMobileWidth) {
    font-size: 1.125rem; /* 18px */
    line-height: 1.625rem; /* 26px */
  }
}

/* Medium */
@mixin typographyMedium {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 400;
  font-size: 1.125rem; /* 18px */
  line-height: 1.625rem; /* 26px */

  @media (max-width: $maxMobileWidth) {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
  }
}

/* Small*/
@mixin typographySmall {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 400;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */

  @media (max-width: $maxMobileWidth) {
    font-size: 0.875rem; /* 14px */
    line-height: 1.375rem; /* 22px */
  }
}

/* X-small */
@mixin typographyXSmall {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 400;
  font-size: 0.875rem; /* 14px */
  line-height: 1.375rem; /* 22px */

  @media (max-width: $maxMobileWidth) {
    font-size: 0.8125rem; /* 13px */
    line-height: 1.25rem; /* 20px */
  }
}

/* Tiny */
@mixin typographyTiny {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 300;
  font-size: 0.75rem; /* 12px */
  line-height: 1.125rem; /* 18px */

  @media (max-width: $maxMobileWidth) {
    font-size: 0.75rem; /* 12px */
    line-height: 1.125rem; /* 18px */
  }
}

/* ========================================================================================== */

/* D1 */
@mixin typographyD1 {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 5rem; /* 80px */
  line-height: 5.625rem; /* 90px */

  @media (max-width: $maxMobileWidth) {
    font-size: 1.75rem; /* 28px */
    line-height: 2.25rem; /* 36px */
    letter-spacing: 1px;
  }
}

/* H1 */
@mixin typographyH1 {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 3.125rem; /* 50px */
  line-height: 3.625rem; /* 58px */

  @media (max-width: $maxMobileWidth) {
    font-size: 1.625rem; /* 26px */
    line-height: 2.125rem; /* 34px */
  }
}

/* H2 */
@mixin typographyH2 {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 2.5rem; /* 40px */
  line-height: 3rem; /* 48px */

  @media (max-width: $maxMobileWidth) {
    font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */
  }
}

/* H3 */
@mixin typographyH3 {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 1.875rem; /* 30px */
  line-height: 2.375rem; /* 38px */
  letter-spacing: 0px;

  @media (max-width: $maxMobileWidth) {
    font-size: 1.375rem; /* 22px */
    line-height: 1.875rem; /* 30px */
    letter-spacing: 1px;
  }
}

/* H4 */
@mixin typographyH4 {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */

  @media (max-width: $maxMobileWidth) {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
  }
}

/* H5 */
@mixin typographyH5 {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 1.125rem; /* 18px */
  line-height: 1.625rem; /* 26px */

  @media (max-width: $maxMobileWidth) {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
  }
}

/* H6 */
@mixin typographyH6 {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */

  @media (max-width: $maxMobileWidth) {
    font-size: 0.875rem; /* 14px */
    line-height: 1.375rem; /* 22px */
  }
}

/* ========================================================================================== */

/* CTA X-Large */
@mixin typographyCtaXLarge {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 1.125rem; /* 18px */
  line-height: 1.25rem; /* 20px */
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* CTA Large */
@mixin typographyCtaLarge {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* CTA Medium */
@mixin typographyCtaMedium() {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 0.875rem; /* 14px */
  line-height: 1.375rem; /* 22px */
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

/* CTA Small*/
@mixin typographyCtaSmall {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 0.75rem; /* 12px */
  line-height: 1.125rem; /* 18px */
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

/* ========================================================================================== */

@mixin typographyCtaSmallOrMedium {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 0.875rem; /* 14px */
  line-height: 1.375rem; /* 22px */
  letter-spacing: 0.5px;
  text-transform: uppercase;

  @media (max-width: $maxMobileWidth) {
    font-size: 0.75rem; /* 12px */
    line-height: 1.125rem; /* 18px */
    letter-spacing: 0.5px;
  }
}

@mixin typographyXSmallOrH6 {
  font-family: $fontFamily, 'Inter-fallback', system-ui;
  font-weight: 500;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  text-transform: uppercase;

  @media (max-width: $maxMobileWidth) {
    font-weight: 400;
    font-size: 0.8125rem; /* 13px */
    line-height: 1.25rem; /* 20px */
  }
}

@mixin typographyEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin textLink {
  cursor: pointer;
  color: $gunmetal;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

@mixin linkUnderline {
  @include typographyCtaMedium;
  color: $gunmetal;
  cursor: pointer;
  text-underline-offset: 6px;
  text-decoration: underline;

  &:visited {
    color: $gunmetal;
  }
}
