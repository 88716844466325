@use 'sass/abstract/mixins/button' as *;
@use 'sass/abstract/mixins/typography' as *;
@use 'sass/abstract/mixins/elevation' as *;
@use 'sass/abstract/variables/colors' as *;

// Naming Convetions -> buttonSizeColor - buttonXLargeGunmetal
%buttonXLargeGunmetal {
  @include gunmetalButton('xlarge');
}

%buttonLargeGunmetal {
  @include gunmetalButton('large');
}

%buttonMediumGunmetal {
  @include gunmetalButton('medium');
}

%buttonSmallGunmetal {
  @include gunmetalButton('small');
}

%buttonXLargeWhite {
  @include whiteButton('xlarge');
}

%buttonLargeWhite {
  @include whiteButton('large');
}

%buttonMediumWhite {
  @include whiteButton('medium');
}

%buttonSmallWhite {
  @include whiteButton('small');
}

%buttonXLargeGreen {
  @include greenButton('xlarge');
}

%buttonLargeGreen {
  @include greenButton('large');
}

%buttonMediumGreen {
  @include greenButton('medium');
}

%buttonSmallGreen {
  @include greenButton('small');
}

%buttonTextXLarge {
  @include buttonTextSize('xlarge');
}

%buttonTextLarge {
  @include buttonTextSize('large');
}

%buttonTextMedium {
  @include buttonTextSize('medium');
}

%buttonTextSmall {
  @include buttonTextSize('small');
}

// Original Setup
%buttonContained {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 50px;
  padding: 0px 32px;
  background-color: #1e1e1e;
  color: $white;
  border-radius: 4px;

  @include elevation('bottom', 'small');
  @include typographyCtaLarge;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: $maxMobileWidth) {
    height: 40px;
  }
}

%buttonOutlined {
  @extend %buttonContained;

  background-color: $white;
  border: 1px solid $gunmetal;
  color: $gunmetal;
  &:hover {
    background: $lightYellow;
  }
}

%buttonGunMetal {
  @extend %buttonContained;

  background-color: $gunmetal;
  color: $white;
  &:hover {
    background: rgba(30, 30, 30, 0.75);
  }
}

%buttonText {
  @include typographyCtaLarge;

  color: $gunmetal;
  text-decoration: underline;
  text-decoration-color: $mediumGray;
  border: none;
  justify-content: flex-start;
  min-width: unset;
  box-shadow: unset;
  background-color: unset;
  padding: 0;
  text-decoration-thickness: 1px;
  text-underline-offset: 6px;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
}

%buttonDisabled {
  border: none;
  background-color: $lightGray;
  color: $gunmetal;

  &:hover {
    cursor: default;
  }
}

%defaultSvgButton {
  all: unset;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;

  &:hover {
    background: transparent;
  }

  > svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
