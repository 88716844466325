@use 'sass/abstract/placeholders/typography' as *;
@use 'sass/abstract/variables/colors' as *;

%fieldset {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 0;
  margin: 0 auto;
  width: 100%;
}

%inputBox {
  position: relative;
  input::placeholder {
    visibility: hidden;
    color: transparent;
  }
  input::-moz-placeholder,
  input::-webkit-input-placeholder {
    visibility: hidden;
    color: transparent;
  }
  input:-ms-input-placeholder {
    color: transparent;
    visibility: hidden;
  }
}

%textAreaBox {
  position: relative;
  display: flex;
  flex-direction: column;
  textarea::placeholder {
    visibility: hidden;
    color: transparent;
  }
  textarea::-moz-placeholder,
  textarea::-webkit-input-placeholder {
    visibility: hidden;
    color: transparent;
  }
  textarea:-ms-input-placeholder {
    color: transparent;
    visibility: hidden;
  }
}

%inputLabel {
  color: $mediumGray;
  display: flex;
  flex-direction: column;
  width: 100%;
  @extend %typographyMedium;
  position: absolute;
  top: -7px;
  left: 0;
  pointer-events: none;
  transform-origin: top left;
  transition: 0.1s ease-in-out transform;
  height: 100%;
  margin: 20px 15px;
  align-items: flex-start;
  justify-content: flex-start;
}

%inputField {
  @extend %typographyMedium;
  background: transparent;
  padding: 20px 15px 10px;
  margin: 0;
  color: $gunmetal;
  width: 100%;
  display: flex;
  height: 52px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid $lightGray;
  &:not([value='']) + label,
  &:focus + label {
    transform: translateY(-10px) scale(0.7);
    width: 100%;
  }
  &:focus {
    // border: 1px solid #333333;
    outline: none;
  }
  &Error {
    border: 1px solid $red;
    background: $red20;
  }
}

%inputError {
  font-size: 12px;
  color: $red;
}

%inputSelectField {
  opacity: 0;
  position: absolute;
  width: 100%;
  min-height: 45px;
  height: 100%;
  z-index: 3;
  cursor: pointer;
}

%textAreaField {
  @extend %typographyMedium;
  background: transparent;
  padding: 20px 15px 15px;
  margin: 0;
  color: $gunmetal;
  width: 100%;
  display: flex;
  min-height: 135px;
  max-height: 200px;
  resize: vertical;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid $lightGray;
  &:not(:empty) + label,
  &:focus + label {
    transform: translateY(-10px) scale(0.7);
  }
  &:focus {
    outline: none;
  }
  &Error {
    border: 1px solid $red;
    background: $red20;
  }
}

%textAreaFieldLabel {
  @extend %inputLabel;
  width: 95%;
}

%textAreaCharLimit {
  color: $mediumGray;
  font-size: 12px;
  align-self: flex-end;
  margin-top: 8px;
}
