// Params = position: 'top' | 'bottom', size: 'xsmall' | 'small' | 'medium' | 'large'
@mixin elevation($position, $size) {
  @if $position == 'bottom' {
    @if $size == 'large' {
      box-shadow: 0px 15px 40px -4px rgba(0, 0, 0, 0.14), 0px 4px 8px -2px rgba(17, 17, 17, 0.05);
    } @else if $size == 'medium' {
      box-shadow: 0px 10px 15px -3px rgba(17, 17, 17, 0.1), 0px 4px 6px -2px rgba(17, 17, 17, 0.05);
    } @else if $size == 'small' {
      box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1), 0px 2px 4px -1px rgba(17, 17, 17, 0.06);
    } @else {
      // default xs
      box-shadow: 0px 1px 3px 0px rgba(17, 17, 17, 0.1), 0px 1px 2px 0px rgba(17, 17, 17, 0.06);
    }
  } @else {
    @if $size == 'large' {
      box-shadow: 0px -15px 40px -4px rgba(0, 0, 0, 0.14), 0px -4px 8px -2px rgba(17, 17, 17, 0.05);
    } @else if $size == 'medium' {
      box-shadow: 0px -10px 15px -3px rgba(17, 17, 17, 0.1),
        0px -4px 6px -2px rgba(17, 17, 17, 0.05);
    } @else if $size == 'small' {
      box-shadow: 0px -4px 6px -1px rgba(17, 17, 17, 0.1), 0px -2px 4px -1px rgba(17, 17, 17, 0.06);
    } @else {
      // default xs
      box-shadow: 0px -1px 3px 0px rgba(17, 17, 17, 0.1), 0px -1px 2px 0px rgba(17, 17, 17, 0.06);
    }
  }
}
