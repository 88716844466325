@use 'sass/abstract/mixins/typography' as *;
@use 'sass/abstract/variables/colors' as *;

// Find and Replace
// @extend %
// @include ...()

// Params - background, color, hoverBackground, hoverColor
@mixin buttonColor($bg: $gunmetal, $color: $white, $hoverBg: false, $hoverColor: false) {
  background-color: $bg;
  color: $color;

  &:hover {
    background: $hoverBg or rgba(red($bg), green($bg), blue($bg), 0.75);
    color: $hoverColor or $color;
  }

  @media (max-width: $maxMobileWidth) {
    background-color: $bg;
    color: $color;

    &:hover {
      background: $hoverBg or rgba(red($bg), green($bg), blue($bg), 0.75);
      color: $hoverColor or $color;
    }
  }
}

@mixin buttonFlexCenter() {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: $gunmetal;
  color: $white;
  text-transform: uppercase;

  &:hover {
    background: rgba(red($gunmetal), green($gunmetal), blue($gunmetal), 0.75);
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
    background: $grayMedLight;
  }

  &[data-hidden='true'] {
    display: none;
  }
}

@mixin buttonXLarge() {
  @include buttonFlexCenter;
  padding: 0 16px;
  height: 60px;
  @include typographyCtaXLarge;
}

@mixin buttonLarge() {
  @include buttonFlexCenter;
  padding: 0 16px;
  height: 50px;
  @include typographyCtaLarge;
}

@mixin buttonMedium() {
  @include buttonFlexCenter;
  padding: 0 8px;
  height: 40px;
  @include typographyCtaMedium;
}

@mixin buttonSmall() {
  @include buttonFlexCenter;
  padding: 0 8px;
  height: 32px;
  @include typographyCtaSmall;
}

// Size: 'xlarge', 'large', 'medium', 'small'
@mixin buttonSize($size) {
  @if $size == 'xlarge' {
    @include buttonXLarge;
  } @else if $size == 'medium' {
    @include buttonMedium;
  } @else if $size == 'small' {
    @include buttonSmall;
  } @else {
    // default large
    @include buttonLarge;
  }

  // Each button will drop one SIZE from Desktop to Mobile - Ex: Large -> Medium
  @media (max-width: $maxMobileWidth) {
    @if $size == 'xlarge' {
      @include buttonLarge;
    } @else if $size == 'large' {
      @include buttonMedium;
    } @else {
      // default Small
      @include buttonSmall;
    }
  }
}

@mixin gunmetalButton($size) {
  @include buttonSize($size);
  @include buttonColor($gunmetal, $white);
}

@mixin whiteButton($size) {
  @include buttonSize($size);
  @include buttonColor($white, $gunmetal, $lightYellow);
  border: 1px solid $gunmetal;
}

@mixin greenButton($size) {
  @include buttonSize($size);
  @include buttonColor($darkGreen, $white);
}

@mixin customButton($size, $bg, $color) {
  @include buttonSize($size);
  @include buttonColor($bg, $color);
}

@mixin buttonTextStyle {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: $gunmetal;
  background: transparent;
  border: none;
  text-transform: uppercase;
  border-bottom: 1px solid $gunmetal;
  width: fit-content;

  @media (min-width: $minDesktopWidth) {
    &:hover {
      border-color: transparent;
    }

    &:hover:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 0;
      border-bottom: 1px solid $gunmetal;
      animation: leftToRight 0.3s linear forwards;
    }

    @keyframes leftToRight {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
  }
  &:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  &[data-hidden='true'] {
    display: none;
  }
}

@mixin buttonTextXLarge {
  @include buttonTextStyle;
  @include typographyCtaXLarge;
  padding-bottom: 6px;
  border-width: 2px;
}

@mixin buttonTextLarge {
  @include buttonTextStyle;
  @include typographyCtaLarge;
  padding-bottom: 4px;
}

@mixin buttonTextMedium {
  @include buttonTextStyle;
  @include typographyCtaMedium;
  padding-bottom: 2px;
}

@mixin buttonTextSmall {
  @include buttonTextStyle;
  @include typographyCtaSmall;
  padding-bottom: 2px;
}

// Size: 'xlarge', 'large', 'medium', 'small'
@mixin buttonTextSize($size) {
  @if $size == 'xlarge' {
    @include buttonTextXLarge;
  } @else if $size == 'medium' {
    @include buttonTextMedium;
  } @else if $size == 'small' {
    @include buttonTextSmall;
  } @else {
    // default large
    @include buttonTextLarge;
  }

  // Each button will drop one SIZE from Desktop to Mobile - Ex: Large -> Medium
  @media (max-width: $maxMobileWidth) {
    @if $size == 'xlarge' {
      @include buttonTextLarge;
    } @else if $size == 'large' {
      @include buttonTextMedium;
    } @else {
      // default Small
      @include buttonTextSmall;
    }
  }
}

@mixin customTextButton($size, $borderColor: 'transparent') {
  @include buttonTextSize($size);
  border-color: $borderColor;

  @media (max-width: $maxMobileWidth) {
    border-color: $borderColor;
  }
}
