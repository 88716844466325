// This is necessary to avoid layout shift with the sizing of headers we defined in Figma
@use 'sass/abstract/placeholders/typography' as *;

.defaultTypographyXLarge {
  @extend %typographyXLarge;
}

.defaultTypographyLarge {
  @extend %typographyLarge;
}

.defaultTypographyMedium {
  @extend %typographyMedium;
}

.defaultTypographySmall {
  @extend %typographySmall;
}

.defaultTypographyXSmall {
  @extend %typographyXSmall;
}

.defaultTypographyTiny {
  @extend %typographyTiny;
}

.defaultTypographyD1 {
  @extend %typographyD1;
}

.defaultTypographyH1 {
  @extend %typographyH1;
}

.defaultTypographyH2 {
  @extend %typographyH2;
}

.defaultTypographyH3 {
  @extend %typographyH3;
}

.defaultTypographyH4 {
  @extend %typographyH4;
}

.defaultTypographyH5 {
  @extend %typographyH5;
}

.defaultTypographyH6 {
  @extend %typographyH6;
}

.defaultTypographyCtaXLarge {
  @extend %typographyCtaXLarge;
}

.defaultTypographyCtaLarge {
  @extend %typographyCtaLarge;
}

.defaultTypographyCtaMedium {
  @extend %typographyCtaMedium;
}

.defaultTypographyCtaSmall {
  @extend %typographyCtaSmall;
}

.defaultTypographyEllipsis {
  @extend %typographyEllipsis;
}

.defaultParagraph {
  @extend %typographySmall;
}

.defaultTypographyCtaSmallOrMedium {
  @extend %typographyCtaSmallOrMedium;
}

.defaultTypographyXSmallOrH6 {
  @extend %typographyXSmallOrH6;
}

.defaultTextTruncate {
  @extend %typographyEllipsis;
}

.defaultTextCenter {
  text-align: center;
}

.defaultTextBold {
  font-weight: bold;
}

.defaultTextCapitalize {
  text-transform: capitalize;
}

.defaultTextUppercase {
  text-transform: uppercase;
}

.defaultLink {
  @extend %textLink;
}

.defaultLinkUnderline {
  @extend %linkUnderline;
}

.defaultLinkAccessibility {
  @extend %accessibilityBorder;
}
