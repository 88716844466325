@use 'sass/abstract/variables/colors' as *;

html {
  font-family: 'Inter', 'Inter-fallback', sans-serif;
  font-size: 16px; /* Set the base font-size to 16px, so 1rem = 16px */
}

body,
button,
a,
input,
textarea,
select {
  font-family: 'Inter', 'Inter-fallback', sans-serif !important;
}

button {
  cursor: pointer;
}

.hrDivider {
  background: $mediumGray;
  border: 0;
  height: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
}

:root {
  --polaroid-width: 300px;
  --polaroid-list-columns-number: 2;
  --polaroid-list-column-gap: 24px;

  @media (min-width: 1440px) {
    --polaroid-list-columns-number: 3;
  }
}

*:focus-visible {
  outline: $darkBlue solid 2px;
  outline-offset: 1px;
}
