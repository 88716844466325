@font-face {
  font-weight: 400;
  font-family: 'Inter';

  src: url('https://www.saatchiart.com/saatchi-general/static/fonts/Inter-Regular.woff2')
      format('woff2'),
    url('https://d3t95n9c6zzriw.cloudfront.net/static/fonts/Inter-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-family: 'Inter';

  src: url('https://www.saatchiart.com/saatchi-general/static/fonts/Inter-Medium.woff2')
      format('woff2'),
    url('https://d3t95n9c6zzriw.cloudfront.net/static/fonts/Inter-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-weight: 600;
  font-family: 'Inter';

  src: url('https://www.saatchiart.com/saatchi-general/static/fonts/Inter-SemiBold.woff2')
      format('woff2'),
    url('https://d3t95n9c6zzriw.cloudfront.net/static/fonts/Inter-SemiBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-weight: 300;
  font-family: 'Inter';

  src: url('https://www.saatchiart.com/saatchi-general/static/fonts/Inter-Light.woff2')
      format('woff2'),
    url('https://d3t95n9c6zzriw.cloudfront.net/static/fonts/Inter-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Inter-fallback';
  size-adjust: 107%;
  ascent-override: 90%;
  src: local('Arial');
}
