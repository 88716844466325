@use 'sass/abstract/placeholders/button' as *;

.defaultButton {
  @extend %buttonContained;
}

.defaultButtonOutlined {
  @extend %buttonOutlined;
}

.defaultButtonText {
  @extend %buttonText;
}

.defaultButtonDisabled {
  @extend %buttonDisabled;
}

.defaultButtonGunMetal {
  @extend %buttonGunMetal;
}

.defaultSvgButton {
  @extend %defaultSvgButton;
}

.defaultLoadingSpinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
