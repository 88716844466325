body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body-lock-scroll {
  @media (min-width: 768px) {
    position: fixed;
    width: 100%;
  }

  overflow: hidden;
}

* {
  box-sizing: border-box;
}

/* Remove Arrow / Spinners on INPUTS */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

/* Hide Lazy Loaded Images */
img:not([src]) {
  visibility: hidden;
}

/* HelloBar */
.HB-Bar {
  position: static !important;
  margin-top: 56px !important;

  @media (min-width: 992px) {
    margin-top: 0 !important;
  }
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #cccccc;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #cccccc, 0 0 5px #cccccc;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #cccccc;
  border-left-color: #cccccc;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  0% {
    background-position: 150%;
  }
  100% {
    background-position: -50%;
  }
}

.greenLoader {
  /* opacity:0; */
  background-color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
  will-change: opacity;
  transition: 0.3s ease-in-out opacity;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    width: 40px;
    height: 40px;
    background-color: #80bc31;
    border-radius: 100%;
    animation: greenBall 0.9s infinite ease-in-out;
    transform-origin: center center;
    will-change: transform, opacity;
  }
}

@keyframes greenBall {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

// #saatchi_promo_nano_bar {
//   width: 100%;
//   height: 60px;
//   @media (max-width: 991px) {
//     height: 0;
//     + #__next {
//       nav[data-section='top-navigation'] {
//         height: 60px;
//       }
//       nav[data-section='main-navigation'] {
//         height: 54px;
//       }
//     }
//   }
// }

@media (max-width: 999px) {
  nav[data-section='main-navigation'] {
    position: sticky;
    top: 0;
    z-index: 110;
  }

  nav[data-section='studio-navigation'] {
    a {
      text-decoration: none;
    }
    button {
      padding: 0; // Fix WP styling
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

[data-promo='hidden'] {
  #saatchi_promo_nano_bar {
    display: none;
  }
}

@media (max-width: 999px) {
  [data-promo='visible'] {
    nav[data-section='main-navigation'] {
      margin-top: var(--promo-bar-height);
    }
    [data-style='checkout-shipping-billing'] {
      margin-top: calc(var(--promo-bar-height) * -1);
    }
  }
}
