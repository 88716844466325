$almostWhite: #f1f1f1;
$blue: #d6ebf5;
$cream: #fcfbf9;
$darkBlue: #3b70c0;
$darkGreen: #248822;
$darkRed: #930811;
$faceBookBlue: #1877f2;
$grayBlue: #6e7580;
$grayMedLight: #b4b4b4;
$green: #d5ecda;
$gunmetal: #1e1e1e;
$gunmetalOpacity: (
  25: rgba(30, 30, 30, 0.25),
  50: rgba(30, 30, 30, 0.5),
  60: rgba(30, 30, 30, 0.6),
  75: rgba(30, 30, 30, 0.75),
);
$lightBlue: #f2f4f5;
$lightGray: #b4b4b4;
$lightGreen: #eff4f0;
$lightYellow: #f8f5ee;
$mediumGray: #737373;
$paleGray: #d8d8d8;
$red: #da3b1f;
$red20: #fbebe9;
$white: #fff;
$yellow: #fdf1d0;
$darkYellow: #fb0;
